<template>
	<div class="product">
		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageProduct" :isBannerReady="isContentReady"></gadget-ads-banner>

			<template v-if="isContentReady">
				<gadget-step-purchase :step="1"></gadget-step-purchase>

				<button @click="returnToSearch()" class="btn btn-back-link mobile">กลับสู่หน้าค้นหา</button>
			</template>

			<section class="content-container mt-1">
				<div class="filter">
					<div class="loading" v-if="!isContentReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>
					<template v-if="isContentReady">
						<div class="box product-navigator">
							<div class="navigator-title"><b-icon icon="sliders" class="icon"></b-icon> ยางที่ค้นหา</div>
							<product-navigator :productData="productData" v-if="isContentReady"></product-navigator>
							<button @click="returnToSearch()" class="btn btn-back-link">กลับสู่หน้าค้นหา</button>
						</div>
					</template>
				</div>
				<div class="content-body" @copy.prevent @paste.prevent>
					<div class="loading" v-if="!isContentReady">
						<div class="main">
							<loader-content :isDisplay="true"></loader-content>
						</div>
						<article class="mt-2">
							<loader-content :isDisplay="true"></loader-content>
						</article>
					</div>

					<template v-if="isContentReady">
						<product-detail :product="productData"></product-detail>
					</template>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import ProductService from '@/services/productService';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import ProductNavigator from '@/components/product/ProductNavigator';
import ProductDetail from '@/components/product/ProductDetail';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';

export default {
	components: {
		GadgetAdsBanner,
		ProductNavigator,
		ProductDetail,
		GadgetStepPurchase
	},
	data() {
		return {
			constants: Constants,
			productData: null,
			isContentReady: false,
			topBanner: null,
			pageTitle: Helper.metaDefault().title,
			metaDescription: Helper.metaDefault().desc,
			metaPicture: location.origin + Helper.metaDefault().logo,
			canonicalUrl: ''
		};
	},
	mounted() {
        this.getContent();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamProduct();
            const result = await ProductService.getProductDetail(param);

			if (result.status == 404) {
				this.$router.push('/404');
			} else if (result.data) {
				this.canonicalUrl = location.protocol + "//" + window.location.host + RouterPath.TIRE + '/' + decodeURI(result.data.productData.param);
				this.topBanner = result.data.ads;
				this.productData = result.data.productData;
				this.pageTitle = this.getTitle(this.productData);
				this.metaDescription = this.productData.shortDesc;
				this.metaPicture = this.productData.picture;
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}

			this.isContentReady = true;
        },
		setupParamProduct() {
			const param = {
				id: Helper.splitDotParam(this.$route.params.id),
				urlReferrer: document.referrer
			};

			return param;
		},
		getTitle(data) {
			return [ 'ยางรถยนต์', data.brand, 'รุ่น', data.name, data.size ].join(' ') + ' | YELLOWTiRE';
		},
		returnToSearch() {
			const filter = localStorage.filter ? localStorage.filter : '';
			
			if (filter) {
				const content = JSON.parse(decodeURIComponent(atob(filter)));
				const returnUrl = '/' + content.searchPage + content.querystring + (content.querystring ? '&' : '?') + 'filter=true';

				this.$router.push(returnUrl);
			} else {
				this.$router.push(RouterPath.TIRE);
			}
		}
    },
	metaInfo() {
        return {
            title: this.pageTitle,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: this.metaDescription },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: this.resultTitle },
                { vmid: 'og-desc', property: 'og:description', content: this.metaDescription },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: this.metaPicture },
                { vmid: 'twitter-description', property: 'twitter:description', content: this.metaDescription },
                { vmid: 'twitter-title', property: 'twitter:title', content: this.pageTitle },
                { vmid: 'twitter-image', property: 'twitter:image', content: this.metaPicture }
            ],
			link: [
				{ rel: 'canonical', href: this.canonicalUrl }
			]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product {
	padding: 25px 0 30px;

	.btn-back-link {
		display: block;
		background: #ffee0e;
		border: none;
		width: 100%;
		font-size: 13px;
		font-weight: bold;
		margin-top: 20px;
		padding: 8px 0;
		@include borderRadius(100px);
		@include boxShadow(0 0 5px rgba(0, 0, 0, 0.1));

		&.mobile {
			display: none;

			@media only screen and (max-width: $screenExtraLarge) {
				display: block;
				width: 200px;
				margin: 20px auto;
			}
		}
	}

	.product-navigator {
		@media only screen and (max-width: $screenExtraLarge) {
			display: none;
		}

		.navigator-title {
			margin: 0;
			font-family: 'Open Sans', 'Kanit';
			font-size: 16px;
			font-weight: bold;

			.icon {
				margin-right: 5px;
			}
		}
	}

	.main {
		width: 100%;
		height: 290px;
	}

	article {
		height: 200px;
	}
}
</style>