<template>
    <div name="modal">
        <div class="modal-full modal-mask" @contextmenu="handlerContext($event)" @keydown="handleKeydown($event)">
            <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
            <vue-pdf-app :pdf="file" :config="config"></vue-pdf-app>
        </div>
    </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import 'vue-pdf-app/dist/icons/main.css';

export default {
    components: {
        VuePdfApp
    },
    data() {
        return {
            config: {
                secondaryToolbar: false,
                toolbar: {
                    toolbarViewerRight: false
                }
            },
            pageYAxis: 0
        }
    },
	props: {
        file: [String, ArrayBuffer]
    },
    created() {
        this.pageYAxis = window.pageYOffset;

        document.body.style.top = -this.pageYAxis + 'px';
        document.body.classList.add('lock');
        document.addEventListener('keydown', this.handleKeydown);
    },
    methods: {
        hide() {
            document.body.classList.remove('lock');
            document.removeEventListener('keydown', this.handleKeydown);
            window.scrollTo(0, this.pageYAxis);

            this.$emit('close');
        },
        handlerContext(e) {
            e.preventDefault();
        },
        handleKeydown(e) {
            if(e.ctrlKey && (e.which === 83)){
                e.preventDefault();
                e.stopPropagation();
                return false;
            }

            return true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-full {
    background: rgba(0, 0, 0, 0.75);

    .btn-close {
        position: fixed;
        z-index: 10000;
        top: 4px;
        right: 4px;
        background: #333;
        color: #fff;
        font-size: 22px;
        line-height: 20px;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
    }
}
</style>